.price {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  }
  
  .carousel {
    @mixin hover {
      .carouselControls {
        opacity: 1;
      }
    }
  }
  
  .carouselControls {
    transition: opacity 150ms ease;
    opacity: 1;
  }
  
  .carouselIndicator {
    width: rem(4px);
    height: rem(4px);
    transition: width 250ms ease;
  
    &[data-active] {
      width: rem(16px);
    }
  }